import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Error404 from "./pages/Error404";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

import About from "./pages/About";
import Bio from "./pages/Bio";
import Linux from "./pages/Linux";
import Locations from "./pages/Locations";
import Nano from "./pages/Nano";
import Bash from "./pages/Bash";
import Mysql from "./pages/Mysql";
import Git from "./pages/Git";
import Vim from "./pages/Vim";
import Postgres from "./pages/Postgres";

function App() {
    return (
        <div className="App container mx-auto">
            <header className="p-2 sm:p-0">
                <h1 className="text-5xl font-bold mt-5 mb-10"><a href="//nlud.uk/">nlud.uk</a></h1>
            </header>
            <Nav />
            <Routes>
                <Route path="/" element={<About />} />
                <Route path="bio" element={<Bio />} />
                <Route path="linux" element={<Linux />} />
                <Route path="nano" element={<Nano />} />
                <Route path="vim" element={<Vim />} />
                <Route path="locations" element={<Locations />} />
                <Route path="bash" element={<Bash />} />
                <Route path="mysql" element={<Mysql />} />
                <Route path="git" element={<Git />} />
                <Route path="postgres" element={<Postgres />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
