import React from "react";


export default function Mysql() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">MySQL</h2>


            <h3 className="sub-head">Using MySQL command from the Linux command line</h3>

            <p>
                <code>mysql -u [username] -p</code><br/>
                Login to MySQL from shell.
            </p>
            <p>
                <code>sudo su -lc mysql</code><br/>
                Login to MySQL from shell without having to use a username.
            </p>
            <p>
                <code>mysqldump -u [username] -p --all-databases > /var/www/foldername/alldb_backup.sql</code><br/>
                Back up all databases to a file (from Linux shell).
            </p>
            <p>
                <code>sudo su -lc mysqldump --all-databases > /var/www/foldername/alldb_backup.sql</code><br/>
                Back up all databases to a file (from Linux shell) without using a login.
            </p>
            <p>
                <code>mysql database_name &lt;  /path/to/backup.sql</code><br/>
                Importing a SQL file into a specific database.
            </p>
            <p>
                <code>service mysql stop</code><br/>
                Shutdown MySQL, also restart.
            </p>


            <h3 className="sub-head">MySQL and MariaDB Config and Optimization</h3>
            <p>Find variables and set variables after logging in to mysql.</p>
            <p>
                <code>SHOW VARIABLES LIKE "%version%";</code><br/>
                Show a table of the MySQL variables containing the string "version", this will include the MySQL
                version.
            </p>
            <p>
                <code>SHOW VARIABLES LIKE 'innodb_buffer%';</code><br/>
                This command can also be used to find the MySQL global variables.
            </p>
            <p>
                <code>SET GLOBAL innodb_fast_shutdown = 0;</code><br/>
                Set the global variable, innodb_fast_shutdown.
            </p>
            <p>
                <code>EXPLAIN SELECT * FROM table_name;</code><br/>
                The EXPLAIN statement provides information about how MySQL executes statements.
            </p>
            <p>
                <code>describe table_name;</code><br/>
                Show the column headings and the settings of each column, e.g. integer, string, default values.
            </p>


            <h3 className="sub-head">General SQL for MySQL and MariaDB</h3>
            <p>
                <code>SHOW DATABASES;</code><br/>
                Within mysql, show all databases.
            </p>
            <p>
                <code>SHOW TABLES;</code><br/>
                Within a database, show all tables.
            </p>
            <p>
                <code>USE db_name;</code><br/>
                Where db_name is the name of the database you want to query.
            </p>

            <p>
                <code>SELECT * FROM table_name WHERE id=1 LIMIT 10;</code><br/>
                This is an example command line SQL command. Note: it needs the semicolon at the end.
            </p>
            <p>
                <code>exit</code><br/>
                Leave mysql and return to the Linux CLI. Exit doesn't require a semicolon.
            </p>


            <h3 className="sub-head">MySQL User Admin</h3>
            <p>
                <code>CREATE USER 'newuser'@'localhost' IDENTIFIED BY 'password';</code><br/>
                Create a new user.
            </p>
            <p>
                <code>GRANT ALL PRIVILEGES ON * . * TO 'newuser'@'localhost';</code><br/>
                Grant privileges to a user.
            </p>
            <p>
                <code>SELECT Host, User FROM mysql.user;</code><br/>
                Show all the users for this instance of mysql.
            </p>
            <p>
                <code>show grants;</code><br/>
                Show the grants for the current database.
            </p>
            <p>
                <code>GRANT ALL PRIVILEGES ON *.* TO 'user_name'@'host';</code><br/>
                Grant privileges for all tables on all databases to a particular user, host can be localhost or a
                particular IP address.
            </p>
            <p>
                <code>FLUSH PRIVILEGES;</code><br/>
                After changing privileges you may need to flush.
            </p>
            <p>
                <code>SELECT * FROM mysql.user WHERE User='username';</code><br/>
                Select info for a particular database user from the user table in the mysql database.
            </p>
            <p>
                <code>show grants for username;</code><br/>
                An alternative (better) way to show permissions for a particular user.
            </p>
            <p>
                <code>revoke all privileges on *.* from 'user'@'host';</code><br/>
                Remove privileges from a user.
            </p>
        </div>
    );
}
