import React from "react";


export default function Linux() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">Basic Linux Reference</h2>

            <h3 className="sub-head">Versions</h3>
            <p>
                <code>php -v</code><br/>
                Which version of PHP is installed?
            </p>
            <p>
                <code>apache2 -v</code><br/>
                Which version of apache is installed? (may need sudo if it doesn't work)
            </p>
            <p>
                <code>lsb_release -a</code><br/>
                Which version of Linux is installed (Debian)?
            </p>
            <p>
                <code>uname -r</code><br/>
                Which kernel version is installed?
            </p>


            <h3 className="sub-head">Users, Ownership and Permissions</h3>
            <p>
                <code>chown -R user:group /path/to/directory</code><br/>
                Change the owner recursively within a specified directory.
            </p>
            <p>
                <code>chmod -R 755 /path/to/directory</code><br/>
                Change all permissions (files and folders) to 755 within a directory
            </p>
            <p>
                <code>find /path/to/directory -type d -exec chmod 755 {} \;</code><br/>
                Change all the directories to 755 within a directory
            </p>
            <p>
                <code>find /opt/lampp/htdocs -type f -exec chmod 644 {} \;</code><br/>
                Change all the files to 644 within a directory
            </p>
            <p>
                <code>grep -Po '^sudo.+:\K.*$' /etc/group</code><br/>
                List all the users with sudo privileges.
            </p>
            <p>
                <code>sudo usermod -aG sudo username</code><br/>
                Add username to the group sudo, can also do this with www-data, etc.
            </p>



            <h3 className="sub-head">File Management</h3>
            <p>
                <code>rm -rf /path/to/directory</code><br/>
                Remove a directory and all it's contents without getting any prompts.
            </p>
            <p>
                <code>mkdir /path/to/directory</code><br/>
                Make a directory.
            </p>
            <p>
                <code>mv</code><br/>
                Move.
            </p>
            <p>
                <code>cp</code><br/>
                Copy.
            </p>
            <p>
                <code>ls /path/to/directory</code><br/>
                List contents of a directory. Optional path, default is current directory.
            </p>
            <p>
                <code>ls -la</code><br/>
                ls command but with the full information including owner and group, and files beginning with a ".".
            </p>
            <p>
                <code>dir</code><br/>
                Similar to <code>ls</code> but nothing is highlighted.
            </p>
            <p>
                <code>file /path/to/html/index.html</code><br/>
                Information about the file.
            </p>
            <p>
                <code>df -h</code><br/>
                Show the diskspace used / available in a human readable format (-h).
            </p>
            <p>
                <code>du -sh /var/www</code><br/>
                Show the summary (s) of disk usage in human readable format (h) for the specified directory.
            </p>
            <p>
                <code>du /var/www -d 1 -h</code><br/>
                If you have multiple website directories in /var/www this will list each directory and give you the
                amount of space used in each (depth of 1).
            </p>
            <p>
                <code>sudo ln -s /some/other/folder</code><br/>
                Create a symlink (symbolic link) in the place you are currently to another directory.
            </p>
            <p>
                <code>sudo find / -name "searchterm"</code><br/>
                Search the current drive for a file with the name "searchterm".
            </p>

            <h3 className="sub-head">Grep</h3>
            <p>
                <code>php -i | grep "pdo"</code><br/>
                Takes the output of php -i and returns only the parts containing "pdo", with the string "pdo"
                highlighted.
            </p>
            <p>
                <code>grep -rnw '/path/to/somewhere/' -e 'needle'</code><br/>
                Searches the directory for files that contain the search string, needle, or a pattern.
            </p>
            <p>
                <code>grep -oE '\bSomething[^,]+' /path/to/csvfile.csv</code><br/>
                Search CSV for rows beginning with Something in a particular CSV.
            </p>

            <h3 className="sub-head">Navigation</h3>
            <p>
                <code>cd ..</code><br/>
                Down one directory (needs the space).
            </p>
            <p>
                <code>cd ../folder</code><br/>
                To a parallel directory.
            </p>
            <p>
                <code>cd -</code><br/>
                Jump straight to the previous location you were at (needs the space), do it twice to take yourself
                back to where you are now.
            </p>
            <p>
                <code>cd ~</code><br/>
                Go to the current user's home.
            </p>
            <p>
                <code>history</code><br/>
                Show a list of previous commands, numbered.
            </p>
            <p>
                <code>!123</code><br/>
                Re-run command numbered 123 from the history command.
            </p>
            <p>
                <code>CTRL - L</code><br/>
                Clear the commandline screen.
            </p>

            <h3 className="sub-head">Useful CLI Commands</h3>
            <p>
                <code>Ctrl-R</code><br/>
                Search for a recently used command.
            </p>


            <h3 className="sub-head">Packages</h3>
            <p>
                <code>dpkg-query -l</code><br/>
                List the installed packages
            </p>
            <p>
                <code>apt-get install [package name]</code><br/>
                Install a new package.
            </p>


            <h3 className="sub-head">Crontab / Cron</h3>
            <p>
                <code>crontab -e</code><br/>
                An easy way to edit the crontab.
            </p>

            <p>
                minute hour day-of-month month day-of-week command<br/>
                <code>* * * * * /usr/bin/wget -q -O /dev/null http://www.url.of/script.php</code><br/>
                /usr/bin/wget = access a script via a URL (can be PHP)<br/>
                -q = quiet<br/>
                -O /dev/null = write the results of the script to nowhere (i.e. do not store it anywhere)<br/>
                -O /path/to/output.file = write the results of the script to a file
            </p>

            <h3 className="sub-head">AWS CLI</h3>
            <p>
                <code>aws sts get-caller-identity</code><br/>
                Which is the current default AWS user.
            </p>
            <p>
                <code>cat ~/.aws/credentials</code><br/>
                View the current AWS CLI credentials on this machine.
            </p>
        </div>
    );
}
