import React from "react";


export default function Git() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">GIT</h2>

            <h3 className="sub-head">Git Commands from the Linux CLI</h3>
            <p>
                <code>git --version</code><br/>
                Find which version of Git you have installed.
            </p>
            <p>
                <code>git checkout -b [branch name]</code><br/>
                Create and checkout a branch.
                Remove -b flag if the branch is already created, then tab for autocomplete will work.
            </p>
            <p>
                <code>git branch [branch name]</code><br/>
                Create a new branch.
            </p>
            <p>
                <code>git pull origin</code><br/>
                Pull the current branch from the repo.
            </p>
            <p>
                <code>git add .</code><br/>
                Add (stage) all files that have been modified or created.
            </p>
            <p>
                <code>git add -A</code><br/>
                Add (stage) all files without a message.
            </p>
            <p>
                <code>git add path/to/file_name</code><br/>
                Add one file.
            </p>
            <p>
                <code>git commit -m "some commit"</code><br/>
                Commit with a message.
            </p>
            <p>
                <code>git push origin</code> or <code>git push -u origin HEAD</code><br/>
                Push current branch.
            </p>
            <p>
                <code>git branch -a</code> or <code>git branch</code><br/>
                Shows all the branches, -a flag reads the branches from the repository too.
            </p>
            <p>
                <code>git status</code><br/>
                Shows all the files that are different from the repository.
            </p>
            <p>
                <code>git diff</code><br/>
                Shows the difference between two commits.
                No arguments, like this, will show all the un-committed changes.
            </p>
            <p>
                <code>git diff master.. --name-only</code><br/>
                Show the difference between the master branch and the current working tree. <code>--name-only</code> shows only the
                filenames that are different.
            </p>
            <p>
                <code>git diff [commit ID]..[commit ID]</code><br />
                Show the difference between two commits.
            </p>
            <p>
                <code>git log -10 --pretty --oneline</code><br/>
                Show my last 10 commits in a concise, readable way.
            </p>
            <p>
                <code>git rm filename</code><br/>
                Remove a file from git.
            </p>
            <p>
                <code>git tag</code><br/>
                Shows all the tags for this repository.
            </p>
            <p>
                <code>git tag 1.0.12</code><br/>
                Make a new tag.
            </p>
            <p>
                <code>git push --tags</code><br/>
                After adding a new tag push it to the repository.
            </p>
            <p>
                <code>git merge develop</code><br/>
                Whatever the current branch is, this command merges develop into that branch.
            </p>
            <p>
                <code>git checkout --</code><br/>
                Reverts all changes to known files in that folder.
            </p>



          <h3 className="sub-head">Delete everything that is not committed and revert back to a previous commit</h3>
          <p>
            Also, find the commit id first.
          </p>
          <ol>
            <li><code>git log</code></li>
            <li><code>git reset --hard 0d1d7fc32</code></li>
          </ol>


            <h3 className="sub-head">Delete a Branch locally and make sure it's deleted on the Repo</h3>
            <p>
                The small -d flag only deletes if it has been merged, -D deletes whether it is merged or not.
            </p>
            <ol>
              <li><code>git push -d origin branch_name</code></li>
              <li><code>git branch -d branch_name</code></li>
            </ol>
            <p>
              Or, alternatively...
            </p>
            <ol>
              <li><code>git branch -D branch_name</code></li>
              <li><code>git push origin :branch_name</code></li>
            </ol>

            <h3 className="sub-head">Git Flow (hotfix)</h3>
            <p>
                <code>git flow hotfix start hotfix_name</code><br/>
                Start a new hotfix.
            </p>
            <p>
                <code>git flow hotfix finish hotfix_name</code><br/>
                This finishes the hotfix, i.e. merges it with master. Master should then be merged into develop.
            </p>
            <p>
                <code>git flow hotfix delete hotfix_name</code><br/>
                You no longer want to continue with the hotfix. It is not needed and just wants to be deleted.
            </p>


            <h3 className="sub-head">Undo a commit and redo</h3>
            <p>
                This is an example of making a commit then wanting to undo that commit before it has been pushed.
            </p>
            <ol>
                <li><code>git commit -m "Something terribly misguided"</code></li>
                <li><code>git reset HEAD~</code> (unstage changes)</li>
                <li><em>edit files as necessary</em></li>
                <li><code>git add ... </code></li>
                <li><code>git commit -c ORIG_HEAD</code> (this re-uses the commit message, you can also just make a
                    new message here)
                </li>
            </ol>


            <h3 className="sub-head">GIT fastforwarding and merge conflicts</h3>
            <p>
                This is an example of a merge that produces conflicts.
            </p>
            <ol>
                <li><code>git checkout develop</code></li>
                <li><code>git pull develop</code></li>
                <li><code>git checkout branch_name</code></li>
                <li><code>git merge develop</code></li>
                <li><em>** There are conflicts (continue) or if no conflicts skip to end and just push. **</em></li>
                <li><code>git status</code></li>
                <li><em>Go to the code in text editor and select which code you want to keep (either head or branch)
                    or combine the two. If the error is with the composer lock file you may need to delete the
                    composer lock, delete the vendor folder, `composer clearcache`, then `composer install` before
                    continuing. Once all the conflicts have been fixed either manually or with mergetool the files
                    can be added.</em></li>
                <li><code>git add path/to/file.php</code></li>
                <li><code>git commit -m 'explanation'</code></li>
                <li><code>git push</code></li>
            </ol>


        </div>
    );
}
