import React from "react";


export default function Vim() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">Vim</h2>

            <h3 className="sub-head">Useful Commands to Start</h3>
            <ul>
              <li>
                <code>ESC</code> - Enter normal mode (for commands to work)
              </li>
              <li>
                <code>:q</code> - Exit VIM
              </li>
              <li>
                <code>:qa!</code> - Abandon all changes and exit VIM
              </li>
              <li>
                <code>:wq</code> - Save and exit
              </li>
              <li>
                <code>:help</code> - Help
              </li>
              <li>
                <code>:set nocp</code> - Vim Defaults
              </li>
              <li>
                <code>:help cp-default</code> - Help on defaults
              </li>
            </ul>


            <h3 className="sub-head">Navigation</h3>
            <ul>
              <li>
                <code>h</code> - left
              </li>
              <li>
                <code>l</code> - right
              </li>
              <li>
                <code>j</code> - down
              </li>
              <li>
                <code>k</code> - up
              </li>
            </ul>

            <h3 className="sub-head">Selecting</h3>

            <ul>
              <li>With the cursor in the correct position go into visual mode, <code>v</code>.</li>
              <li>Expand the selection from the original point of the cursor with navigation, see above.</li>
            </ul>

            <p>
              Instead of opening visual mode with lowercase <code>v</code>, use uppercase <code>V</code> for linewise visual mode,
                i.e. select a line at a time and <code>j</code> and <code>k</code> to expand.
            </p>

            <p>
              Alternatively, <code>Ctrl</code>-<code>v</code> enters block visual mode where you can select blocks of text without including the entire line.
            </p>

            <h3 className="sub-head">Copy and Paste</h3>

            <p>
              After selecting...
            </p>

            <ul>
              <li><code>d</code> - delete</li>
              <li><code>y</code> - yank (copy)</li>
              <li><code>p</code> - paste</li>
              <li><code>c</code> - change (delete and put cursor at the start for typing)</li>
              <li><code>r</code> - replace (replace the text with the next character you type)</li>
              <li><code>y</code><code>q</code><code>/</code><code>p</code> - search for the text elsewhere in the same document,  <code>y</code> (yank), <code>q</code><code>/</code> (slash = search mode), <code>p</code> (paste)</li>
            </ul>


          <h3 className="sub-head">More Basic Usage</h3>
            <p>
                <code>i</code> - insert mode
            </p>
            <p>
                <code>a</code> - append
            </p>
            <p>
                <code>x</code> - delete
            </p>
            <p>
                <code>dw</code> - delete word (cursor on first letter)
            </p>
            <p>
                <code>d$</code> - delete rest of line from cursor onwards
            </p>
            
        </div>
    );
}
