import React from "react";
import { ReactComponent as TwitterLogo } from '../twitter-brands.svg';

function Footer() {
    let year = new Date().getFullYear().toString().substr(-2);
    return (
        <div className="w-100 border-t border-black border-solid mt-10">
            <div className="w-full block mx-auto text-center pt-3">
                &copy;2016-{year} <a href="//nlud.uk/">nlud.uk</a> by <a href="//shortdark.co.uk/" rel="noreferrer noopener" target="_blank">Shortdark</a>.
                Made with <a href="//reactjs.org/" rel="noreferrer noopener" target="_blank">ReactJS</a> and <a href="//tailwindcss.com/" rel="noreferrer noopener" target="_blank">Tailwind CSS</a>.
                <span className="inline-block align-middle pl-2">
                    <a href="https://twitter.com/shortdark" rel="noreferrer noopener" target="_blank">
                        <TwitterLogo className="w-4 fill-blue-400" />
                    </a>
                </span>
            </div>
        </div>

    );
}

export default Footer;
