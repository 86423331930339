import React from "react";
import { Link, useLocation } from "react-router-dom"


export default function Error404() {
    let location = useLocation();
    return (
        <div className="p-2 sm:p-0">
            <h1>This page does not exist</h1>
            <p>Resource not found at {location.pathname}!</p>
            <p>Try <Link to="/">something else</Link>.</p>
        </div>
    );
}
