import React from "react";


export default function Bio() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">Bio</h2>

            <p>
                <img src="https://content.exp-sd.com/shortdark-white.jpg" alt="Neil Ludlow" className="w-52" />
            </p>

            <p>
                I am a web developer called <strong>Neil Ludlow</strong> from Sheffield, UK,
              and <abbr title="Neil Ludlow">nlud</abbr> is a personal website that is mainly Linux reference material.
            </p>

            <h3 className="sub-head">Current Stack and Tools</h3>

            <ul className="list-disc">
                <li>PHP: including "No Framework", Composer packages and WordPress plugins</li>
                <li>PHP Frameworks: Laravel, CodeIgniter, Symfony, WordPress</li>
                <li>Databases: MySQL, MariaDB, Sqlite, Postgres, MongoDB</li>
                <li>OS: Linux (Ubuntu, Debian, AWS Linux and CentOs)</li>
                <li>Web servers: Nginx and Apache</li>
                <li>AWS: S3, EB, EC2, CloudFront, WAF, Lambda@Edge, IAM, RDS, Lightsail, CodePipeline, CodeDeploy</li>
                <li>CDN: CloudFlare (and AWS CloudFront)</li>
                <li>Source control: Git (and SVN)</li>
                <li>Javascript: Vanilla javascript, jQuery, GatsbyJs, VueJs, NodeJs, ReactJs, React Native and NestJs.</li>
                <li>CSS: plain, Bootstrap, Tailwind, Bulma</li>
                <li>Also, some Python.</li>
            </ul>
        </div>
    );
}
