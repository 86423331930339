import React from "react";

import { NavLink } from "react-router-dom"

function Nav() {
    
    return (
        <div className="Nav">
            <nav>
                <ul className="flex">
                    <li><NavLink to="/">About</NavLink></li>
                    <li><NavLink to="bio">Bio</NavLink></li>
                    <li><NavLink to="linux">Linux</NavLink></li>
                    <li><NavLink to="nano">Nano</NavLink></li>
                    <li><NavLink to="vim">Vim</NavLink></li>
                    <li><NavLink to="locations">Linux Locations</NavLink></li>
                    <li><NavLink to="bash">BASH</NavLink></li>
                    <li><NavLink to="mysql">MySQL</NavLink></li>
                    <li><NavLink to="postgres">PostgreSQL</NavLink></li>
                    <li><NavLink to="git">GIT</NavLink></li>
                </ul>
            </nav>
        </div>
    );
}

export default Nav;
