import React from "react";


export default function Bash() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">Bash</h2>


            <h3 className="title is-3">Bash Checklist</h3>
            <ul className="list-disc">
                <li>Create a folder for your bash scripts, i.e. <strong>~/bin</strong></li>
                <li>Edit the ~/.bashrc file and add this to the bottom: <strong>export
                    PATH=$PATH:/$HOME/bin</strong></li>
                <li>After modifying .bashrc either logout and login, or type <strong>source ~/.bashrc</strong></li>
                <li>Create your script file with this on the first line: <strong>#!/bin/bash</strong></li>
                <li>Save the file with no extension, e.g. <strong>~/bin/my-script</strong></li>
                <li>Make sure the file is executable: <strong>chmod +x ~/bin/my-script</strong></li>
            </ul>


            <h3 className="sub-head">Sudo and Bash</h3>
            <p>
                You can just put sudo commands into the bash script but this is not ideal.
                It is better to omit any sudo commands from within the script, then call the script by <strong>sudo
                my-script</strong> (but this will not work).
                The CLI will balk and say "<strong>Sudo: my-script command not found</strong>".
                To be able to have s bash script with no sudo commands in it that you can call with sudo you would
                follow this stackoverflow Q/A: <a
                href="https://stackoverflow.com/questions/9448920/how-can-i-execute-a-bash-function-using-sudo"
                target="_blank" rel="noopener noreferrer">How can I execute a bash function using sudo?</a>.
                You can also export the code to the .bashrc so that it can be used on different bash scripts.
            </p>


            <h3 className="sub-head">How to create a SFTP user account with a Bash Script</h3>
            <p>
                <code>#!/bin/bash</code><br/>

                # Assuming there is a group called "sftp" you can do this...<br/>

                <code>dt=$(date '+%Y%m%d%H')</code><br/>
                <code>USR="user-$dt"</code><br/>
                <code>sudo mkdir /home/$USR</code><br/>
                <code>sudo useradd $USR -d /home/$USR</code><br/>
                <code>MYPASS=$(head /dev/urandom | tr -dc 'A-Za-z0-9!#$%&":;_^~@?' | head -c 13)</code><br/>
                # Create a random password 13 characters long composed of the listed characters and save it to the
                variable: $MYPASS (no $ when you set it)<br/>
                <code>sudo usermod -aG sftp $USR -p $MYPASS</code><br/>
                # give the user a password and assign to the sftp group<br/>


                <code>sudo chown -R $USR:$USR /home/$USR</code><br/>
                <code>echo $MYPASS</code><br/>
                # Display the $MYPASS variable<br/>
                *****************************<br/>
                <code>USERLIST=$(cut -d: -f1 /etc/passwd)</code><br/>
                <code>echo $USERLIST</code><br/>
                *****************************<br/>
                <code>grep '^sftp:' /etc/group</code>
                <code>userdel $USR</code><br/>
                <code>USERLIST=$(cut -d: -f1 /etc/passwd)</code><br/>
                <code>echo $USERLIST</code><br/>
                *****************************<br/>
                <code>useradd -g sftp -d /home/$USR -p $(echo $MYPASS | openssl passwd -1 -stdin) $USR</code><br/>
                # Add the user all in one line<br/>

            </p>
        </div>
    );
}
