import React from "react";

export default function About() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">About This Website</h2>

            <p>
                This website has never been particularly pretty or complex.
                It has always been a simple place to put things so I don't forget them.
                Like a cheat sheet for some things as I'm learning them.
                The colour scheme has always been black, white and grey.
            </p>

            <p>
                Here is the history of this website, in reverse chronological order.
            </p>

            <h3 className="sub-head">2022</h3>

            <p>
                Mainly as a learning exercise, I have re-coded this website in <strong>ReactJS</strong> with some <strong>TailwindCSS</strong> styling.
                I have also had a look at, and modified, the AWS setup.
                This website currently uses <strong>AWS S3</strong>, <strong>CloudFront</strong> and <strong>CloudFront Functions</strong> with <strong>CloudFlare</strong> on top.
                While in 2020, I focused a fair bit on the JS and CSS, and an equal amount on the AWS side, this time I have mainly been exploring AWS side.
            </p>

            <h3 className="sub-head">2020</h3>

            <p>
                I decided to update the website in 2020 as jQuery was out-of-fashion.
                I'd recently built another test website with Gatsby, which is a React-based static site generator, so I decided to instead build this website in <strong>VueJS</strong>, <strong>Bulma.io</strong> and <strong>Font Awesome</strong>.
            </p>

            <p>
                It was based on a VueJs tutorial that was for tab-based navigation.
                From there I added a Favicon, made the copyright year dynamic and split the content into different Vue files.
                Changed the domain to nlud.uk (from nlud.co.uk) and moved the website to <strong>AWS S3</strong> with <strong>CloudFront</strong>, <strong>WAF</strong> and <strong>Lambda@Edge</strong>, all behind <strong>CloudFlare</strong>.
                It was still a very simple website but it was much better than it used to be.
                The 2020 Vue version now lives <a href="//vue.nlud.uk/" rel="noreferrer noopener" target="_blank">here</a>.
            </p>

            <h3 className="sub-head">2018</h3>

            <p>
                By 2018 I had added quite a bit more information which was still displayed in a long list down an HTML page.
                At this point, I decided to hide everything and create a jQuery navigation.
                When the site loads you just see the seven headers listed vertically, by clicking each arrow you can display the content of that section.
                The website used <strong>jQuery</strong>, <strong>Bootstrap</strong> and <strong>Gylphicons</strong>.
                The jQuery was very simple and clear, the content was stored in include files, and I'm not too embarrassed by the end result in terms of coding.
                The design could always be better but I'm not a designer.
                Here is the <a href="//nlud.uk/001/index.html" rel="noreferrer noopener" target="_blank">2018 version of this website</a>.
            </p>

            <h3 className="sub-head">2016</h3>

            <p>
                In 2016, this website was very basic indeed and just had some Linux and Nano information on an HTML page with some vertical navigation through anchor tags.
                I have re-created <a href="//nlud.uk/000/index.html" rel="noreferrer noopener" target="_blank">this site in 2016</a> from Wayback Machine.
            </p>







            <p>
                See <a href="https://shortdark.co.uk/" rel="noreferrer noopener" target="_blank">shortdark</a> for more in-depth
                information I have posted over the years and links to more test sites.
            </p>

        </div>
    );
}