import React from "react";


export default function Locations() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">Linux Locations</h2>

            <h3 className="sub-head">Linux</h3>
            <p>
                <code>/etc/ssh/sshd_config</code><br/>
                sshd_config - OpenSSH SSH daemon configuration file.
            </p>
            <p>
                <code>/etc/hosts</code><br/>
                Hosts file.
            </p>
            <p>
                <code>/etc/hostname</code><br/>
                Hostname file.
            </p>
            <p>
                <code>/etc/network/interfaces</code><br/>
                Interfaces file - configure IP addresses here.
            </p>
            <p>
                <code>/etc/apt/apt.conf.d/50unattended-upgrades</code><br/>
                Unattended Upgrades.
            </p>
            <p>
                <code>/etc/nanorc</code><br/>
                Nano config file.
            </p>
            <p>
                <code>php -i | grep "php.ini"</code><br/>
                Shows the paths to the php.ini files that are currently loaded.
            </p>
            <p>
                <code>sudo find / -name error.log</code><br/>
                Find all the files with the name error.log.
            </p>


            <h3 className="sub-head">Apache</h3>
            <p>
                <code>/var/www</code><br/>
                The default place where the websites are stored, may be different on different setups.
            </p>
            <p>
                <code>/etc/apache2/sites-available</code><br/>
                Where website config files are created.
            </p>
            <p>
                <code>/var/log/apache2/error.log</code><br/>
                The default error log file.
            </p>


            <h3 className="sub-head">Nginx</h3>
            <p>
                <code>sudo nginx -t</code><br/>
                Tests the nginx config and displays any problems before you restart nginx.
                Also contains the link to the nginx config file.
            </p>


        </div>
    );
}